.rbc-calendar {
  height: 100000px !important;
  font-size: 14px;
}

.rbc-calendar .rbc-event {
  padding: 0px;
}

.rbc-calendar .rbc-row-content {
  overflow: scroll;
}

.rbc-calendar .rbc-time-column .rbc-timeslot-group {
  height: 300px;
}

.rbc-calendar .rbc-day-slot .rbc-event-label {
  display: none;
}

.rbc-calendar .rbc-day-slot .rbc-event {
  height: initial !important;
}

.rbc-agenda-event-cell {
  color: white;
}
